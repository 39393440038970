export const Theme = {
  colors: {
    background: "#000000",
    shapes: "#161618",
    cards: "#161618",
    darkHighlight: "#212124",
    tertiary: "#7d7d7d",
    secundaryDark: "#969696",
    secundaryLight: "#a3a3a3",
    primary: "#e4e6eb",
    green: "#72dbaa",
    turquoise: "#37beb2",
    blue: "#5eb1ec",
    violet: "#9b99ff",
    purple: "#c685ff",
    pink: "#dc84d2",
  },
  spacings: {
    xxSmall: "0.25rem",
  },
  shadows: {
    shadow1: "8px 8px #00000010",
  },
}
