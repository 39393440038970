// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-backstory-js": () => import("./../../../src/pages/backstory.js" /* webpackChunkName: "component---src-pages-backstory-js" */),
  "component---src-pages-best-practices-index-js": () => import("./../../../src/pages/best-practices/index.js" /* webpackChunkName: "component---src-pages-best-practices-index-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mindset-js": () => import("./../../../src/pages/mindset.js" /* webpackChunkName: "component---src-pages-mindset-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-templates-hint-detail-js": () => import("./../../../src/templates/hint-detail.js" /* webpackChunkName: "component---src-templates-hint-detail-js" */)
}

